<template>
    <div>
        <v-app-bar app dark color="#4e419d">
            <div @click="goToHome" style="background-color: white; border-radius: 5px; cursor: pointer;">
                <v-img width="100" :src="getLogoUrl" />
            </div>
            <v-spacer />
            <div v-if="$vuetify.breakpoint.smAndUp">
                <span>{{ getSlogan }}</span>
            </div>
            <v-spacer />
            <div>
                <v-btn @click="goToHome" icon>
                    <v-icon>mdi-home</v-icon>
                </v-btn>

                <v-btn @click="$router.push('/ecommerce/meus-pedidos')" icon>
                    <v-icon>mdi-clipboard-text-clock-outline</v-icon>
                </v-btn>

                <UserOptions v-if="hasToken()" @logout="onLogout"/>

                <v-btn v-else @click="openLoginDialog" text>
                    <v-icon>mdi-account</v-icon>
                    <span v-if="$vuetify.breakpoint.smAndUp">
                        Entre ou Cadastre-se
                    </span>
                </v-btn>
            </div>
        </v-app-bar>

        <LoginDialog
            :visible="loginDialogVisible"
            @login="onLogin"
            @close="loginDialogVisible = false"
        />
    </div>
</template>

<script>
import LoginDialog from '../login/LoginDialog';
import UserOptions from './UserOptions';

export default {
    name: 'HeaderContainer',

    components: {
        LoginDialog,
        UserOptions,
    },

    data: () => ({
        loginDialogVisible: false,
    }),

    computed: {
        getLogoUrl() {
            const logo = this.$store.state.config?.logo;

            const url = (typeof logo === 'object' && logo?.url)
                ? (logo.tmp
                    ? `${this.$urlAPI}/files/tmp/${logo.url}`
                    : `${this.$urlAPI}/storage/${logo.url}`
                ) : require('@/assets/logo.png');

            return url;
        },

        getSlogan() {
            return this.$store.state.config?.slogan || '';
        },
    },

    methods: {
        hasToken() {
            return sessionStorage.getItem('token');
        },

        openLoginDialog() {
            if (this.hasToken()) {
                return;
            }
            this.loginDialogVisible = true;
        },

        onLogin() {
            this.$emit('login');
            this.loginDialogVisible = false;
        },

        onLogout() {
            sessionStorage.clear();
            this.$store.commit('setUser', {});
            this.$forceUpdate();

            this.$router.push('/login');
        },

        goToHome() {
            this.$route.path != '/' && this.$router.push('/');
        },
    },
}
</script>

<style scoped>
.v-text-field__slot {
    color: white;
}
</style>
